import React, { useState, Fragment, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Button,
  IconButton,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Paper,
  Collapse,
  DialogContent,
  Box,
  InputAdornment,
  ButtonGroup,
  Popper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
} from "@mui/material";
import {
  LocationOn,
  Close,
  Clear,

} from "@mui/icons-material";

import { useForm } from "react-hook-form";

import { useQuery, useMutation, gql, useApolloClient } from "@apollo/client";

import {
  SAVE_SHIPMENT_QUERY,
  CALCULATE_FEES_QUERY,
  SHIPMENT_UPDATE_QUERY,
  CUSTOMER_ID,
  BARCODE,
} from "./Graphql";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useTranslation } from "react-i18next";
import { Can } from "../HOC/CustomComponents/Secured";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Globals } from "../HOC/Classes/Globals";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_PAYMENT_TYPES_DROPDOWN,
  LIST_SHIPMENT_TYPES_DROPDOWN,
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import RecipientListPopover from "./RecipientListPopover";
import Grid from "@mui/material/Unstable_Grid2";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import { SEARCH, SUM_SHIPMENT } from "../ShipmentSearch/SearchTable";
import moment from "moment";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { GetManifestPermissionSlug } from "../../helpers/getPermissionSlug";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PrintComponent from "./PrintComponent";
import { printLink } from "../../helpers/defaultPrintList";
import MapComponent from "../HOC/GoogleMap/PickpUpMap";
import { defaultQueryFields, getShipmentsSumQuery, initialData } from "../ShipmentSearch/ListShipmentTableCells";
import getMobileData, { getFormatNumber, validNumber } from "../../helpers/asYouType";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import FormTabs from "./ListProduct/FormTaps";
import ProductList from "./ProductsList/ProductList";
import ControlMUItextMoney from "../HOC/MUI/ControlMUItextMoney";
import formatMoney from "../../helpers/numbersDot";
import MUIDate from "../HOC/MUI/MUIDate";

const PREFIX = "SaveShipment";

const classes = {
  shipmentRoot: `${PREFIX}-shipmentRoot`,
  margin: `${PREFIX}-margin`,
  divider: `${PREFIX}-divider`,
  noPadding: `${PREFIX}-noPadding`,
  footer: `${PREFIX}-footer`,
  calcValue: `${PREFIX}-calcValue`,
  calcTitle: `${PREFIX}-calcTitle`,
  totalCalc: `${PREFIX}-totalCalc`,
  gridWidth: `${PREFIX}-gridWidth`,
  marginTop: `${PREFIX}-marginTop`,
  sectionMargin: `${PREFIX}-sectionMargin`,
  sectionPadding: `${PREFIX}-sectionPadding`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  typography: `${PREFIX}-typography`,
  button: `${PREFIX}-button`,
  recipient: `${PREFIX}-recipient`,
  feesField: `${PREFIX}-feesField`,
  loadingFooter: `${PREFIX}-loadingFooter`,
  addIcon: `${PREFIX}-addIcon`,
  tableTitle: `${PREFIX}-tableTitle`,
  toolbar: `${PREFIX}-toolbar`,
  overlay: `${PREFIX}-overlay`,
  tableField: `${PREFIX}-tableField`,
  saveToolbar: `${PREFIX}-saveToolbar`,
  paperBackground: `${PREFIX}-paperBackground`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.shipmentRoot}`]: {
    position: "relative",
  },

  [`& p`]: {
    lineHeight: "unset"
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    minWidth: "92%",
  },

  [`& .${classes.noPadding}`]: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },

  [`& .${classes.footer}`]: {
    width: "100%",
    fontSize: "15px",
    padding: theme.spacing(1),
    zIndex: 10,
    bottom: "0",
    textAlign: "center",
    position: "sticky",
    backgroundColor: theme.palette.background.paper,
    borderWidth: "2px 0 0",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },

  [`& .${classes.calcValue}`]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    fontSize: 15
  },

  [`& .${classes.calcTitle}`]: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    fontSize: 13
  },

  [`& .${classes.totalCalc}`]: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.gridWidth}`]: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.marginTop}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      // padding: "0 !important",
    },
  },

  [`& .${classes.sectionMargin}`]: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
      width: "100%",
    },
  },

  [`& .${classes.sectionPadding}`]: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.typography}`]: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    },
    marginBottom: 0,
  },

  [`& .${classes.button}`]: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.feesField}`]: {
    padding: theme.spacing(0, 5),
    marginTop: theme.spacing(1),
    "& input": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
      fontSize: 15,
    },
  },

  [`& .${classes.loadingFooter}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    top: 0,
    left: 0,
    "& .MuiGrid-container": {
      height: "100%",
    },
  },
  [`& .${classes.toolbar}`]: {
    width: "100%",
    justify: "space-between",
    marginTop: theme.spacing(2),
  },
  [`& .${classes.saveToolbar}`]: {
    flexDirection: "column",
  },
  [`& .${classes.tableTitle}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.tableField}`]: {
    width: "100px",
    margin: theme.spacing(1, 0),
  },
  [`& .${classes.paperBackground}`]: {
    backgroundColor: theme.palette.background.default,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const parseBool = (bool) => bool === "true";

const dateTimeFormat = (date) =>
  moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const dateFormat = (date) =>
  moment(date).locale("en").format("YYYY-MM-DD");

const SaveShipment = (props) => {
  const {
    closeDialog,
    defaultCustomer,
    anotherShipment,
    anotherShipmentSwitch,
  } = props;

  const [calcFeesState, setCalcFeesState] = useState({
    recipientSubzoneId: null,
    recipientZoneId: null,
    senderSubzoneId: null,
    senderZoneId: null,
    priceType: null,
    paymentType: null,
    price: null,
    weight: null,
    serviceId: null,
    customerId: null,
    deliveryFees: null,
    extraWeightFees: null,
    collectionFees: null,
    returnFees: null,
    size: {
      height: null,
      width: null,
      length: null,
    },
    render: false,
  });

  const [calcFeesValue, setCalcFeesValue] = useState({
    amount: 0,
    tax: 0,
    post: 0,
    total: 0,
  });
  const [calculationChecker, setCalculationChecker] = useState({});

  const [createdAtState, setCreatedAtState] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [editDeliveryDate, setEditDeliveryDate] = useState(null);

  const resetStartDate = () => {
    setDeliveryDate(null);
    setValue("shipment.deliveryDate", null);
  };

  const [unEditable, setUnEditable] = useState(false);
  const [customerWarehousing, setCustomerWarehousing] = useState(false);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    originBranch: null,
    transactionType: null,
    customer: null,
    service: null,
    recipientZone: null,
    recipientSubzone: null,
    senderZone: null,
    senderSubzone: null,
    typeCode: null,
    country: null,
    paymentType: null,
  });

  // const multiCountry = config.app.multiCountry;
  const [locationValue, setLocationValue] = useState();
  const [recipientZones, setRecipientZones] = useState();
  const [changeCustomer, setChangeCustomer] = useState(true);
  const [recipientZonesList, setRecipientZonesList] = useState({
    zonesList: null,
    subzonesList: null,
  });
  const [openMapDialogRecipient, setOpenMapDialogRecipient] = useState(false);
  const [openMapDialogSender, setOpenMapDialogSender] = useState(false);
  const client = useApolloClient();
  const user = Globals.user;
  // const adminNotesPermission = user.hasPermission(
  //   "shipping.shipment.view_admin_note"
  // );
  const countryCode = Globals.settings.countryCode
  const settingsPermission = user.hasPermission(
    "shipping.shipment.choose_transaction_type"
  );
  const adminWarehousingPermission = user.hasPermission(
    "shipping.product.list"
  );
  const deliveryDatePermission = user.hasPermission(
    "shipping.shipment.update_delivery_date"
  );

  const warehousingSystem = Globals.user.warehousing;

  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: settingsPermission
      ? [
        { defaultShippingService: ["id", "name"] },
        { defaultTransactionType: ["id", "name"] },
        // { defaultPaymentType: ["code", "name"] },
        "forcePostalCode",
        "multiCountries",
      ]
      : [
        { defaultShippingService: ["id", "name"] },
        "forcePostalCode",
        "multiCountries",
      ],
    variables: {},
  });

  const { data: settings, loading: settingsLoading } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (settingsPermission) {
          // const defaultPayment = data.shippingSettings.defaultPaymentType.code;
          if (!urlUpdateShipmentId) {
            // setValue("shipment.paymentType", defaultPayment, {
            //   shouldDirty: true,
            //   shouldValidate: true,
            // });
            // if (defaultPayment === "CASH" || defaultPayment === "CRDT") {
            //   calcFeesHandler({ value: 0, name: "price" });
            //   setValue("shipment.price", 0);
            // }

            // calcFeesHandler({ value: defaultPayment, name: "paymentType" });

            setAutocompleteValues((prev) => ({
              ...prev,
              transactionType: data.shippingSettings.defaultTransactionType,
            }));
          }
        }
      },
    }
  );
  const multiCountry = settings?.shippingSettings?.multiCountries;
  const forcePostalCode = !!settings?.shippingSettings?.forcePostalCode;
  const customerPermission = user.hasPermission(
    "shipping.shipment.choose_customer"
  );
  const currencyCode = Globals?.settings?.localCurrency?.name ?? "";;

  const listConsigneePermission = user.hasPermission("shipping.consignee.list");

  let inWarehouseLocalStorage = false;
  if (localStorage.getItem("receiveInWarehouse")) {
    inWarehouseLocalStorage = parseBool(
      localStorage.getItem("receiveInWarehouse")
    );
    localStorage.setItem("receiveInWarehouse", inWarehouseLocalStorage);
  }
  const {
    handleSubmit,
    unregister,
    setValue,
    control,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      shipment: {
        receiveInWarehouse: inWarehouseLocalStorage,
      },
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const customerId = watch("shipment.customerId") ?? user?.account?.id;

  const { errors } = formState;

  const { t } = useTranslation(["translation", "validation"]);
  const options = [t("save"), t("saveAndPrint")];

  const urlUpdateShipmentId = !closeDialog && props?.match?.params?.id;

  const handleClickOpenMapRecipient = () => {
    setOpenMapDialogRecipient(true);
  };
  const handleClickOpenMapSender = () => {
    setOpenMapDialogSender(true);
  };

  const handleCloseMap = () => {
    setOpenMapDialogSender(false);
    setOpenMapDialogRecipient(false);
  };
  const addAddress = () => {
    // Convert coordinates to address using OpenCage Geocoding API
    if (locationValue) {
      const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${locationValue.lat}+${locationValue.lng}&key=65120718cf98432497c7792b14d5260e`;

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          if (data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted;
            if (openMapDialogSender) {
              // setAddressSender(formattedAddress);
              setValue("shipment.senderAddress", formattedAddress);
            }
            else {
              // setAddressRecipient(formattedAddress);
              setValue("shipment.recipientAddress", formattedAddress)
            }
          } else {
            console.error('Geocoding failed');
          }
        })
        .catch(error => console.error('Error fetching geocoding data:', error));
    }
  };

  let weightTimer;
  let priceTimer;
  let heightTimer;
  let widthTimer;
  let lengthTimer;

  useEffect(() => {
    if (defaultCustomer) {
      setAutocompleteValues((prev) => ({
        ...prev,
        customer: defaultCustomer,
      }));
      setCalcFeesState((prev) => ({
        ...prev,
        ...(customerPermission && {
          customerId: defaultCustomer.id,
        }),
      }));
    }
    if (!urlUpdateShipmentId) {
      setValue(
        "shipment",
        {
          weight: 1,
          length: 0,
          width: 0,
          height: 0,
          piecesCount: 1,
          ...(user.account?.name && {
            senderName: user.account?.name,
          }),
        },
        { shouldDirty: true, shouldValidate: true }
      );
      setCalcFeesState((prev) => ({
        ...prev,
        weight: 1,
        size: { length: 0, width: 0, height: 0 },
      }));
    }

    return () => {
      clearTimeout(weightTimer);
      clearTimeout(priceTimer);
      clearTimeout(heightTimer);
      clearTimeout(widthTimer);
      clearTimeout(lengthTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFeesValue = () => {
    setValue("shipment.deliveryFees", "");
    setValue("shipment.extraWeightFees", "");
    setValue("shipment.collectionFees", "");
    setValue("shipment.returnFees", "");
  };

  const firstCalcUpdate = useRef(true);
  const overwriteFees = user.hasPermission("shipping.shipment.overwrite_fees");
  const watchFees = (name) =>
    watch(`shipment.${name}`) || watch(`shipment.${name}`) === 0;
  const validFees =
    watchFees("returnFees") ||
    watchFees("deliveryFees") ||
    watchFees("extraWeightFees") ||
    watchFees("collectionFees");
  const feesPermission = overwriteFees ? !validFees : true;

  const calcFeesHandler = ({ value, name, feesAvailable }) => {
    let deleteFees = {
      deliveryFees: null,
      extraWeightFees: null,
      collectionFees: null,
      returnFees: null,
    };

    if (feesAvailable) {
      deleteFees = {};
    } else {
      resetFeesValue();
    }
    let fieldValue = {
      // eslint-disable-next-line eqeqeq
      [name]: value || value === 0 ? value : null,
    };

    if (name === "width" || name === "length" || name === "height") {
      fieldValue = {
        size: {
          ...calcFeesState.size,
          [name]: value,
        },
      };
    }
    firstCalcUpdate.current = false;
    setCalcFeesState((prev) => ({
      ...prev,
      ...deleteFees,
      ...fieldValue,
      render: !calcFeesState.render,
    }));
  };

  const updateShipment = SHIPMENT_UPDATE_QUERY();
  const { data: shipmentDataForUpdate, loading: shipmentDataForUpdateLoading } =
    useQuery(
      gql`
        ${updateShipment.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        skip: Boolean(!urlUpdateShipmentId),
        variables: {
          id: parseInt(urlUpdateShipmentId),
          excludeShipmentId: parseInt(urlUpdateShipmentId),
        },
        onCompleted: (data) => {
          const shipment = data?.shipment;
          if (shipment?.id) {
            if (!data.shipment?.editable) {
              setUnEditable(true);
            }
            if (data?.shipment?.shipmentProducts) {
              const inProducts = data.shipment.shipmentProducts.filter(product => product.type === "IN");
              const outProducts = data.shipment.shipmentProducts.filter(product => product.type === "OUT");
              if (inProducts.length > 0) {
                shipment.customer.warehousing && setPickedProductIN({ products: inProducts });
              }
              if (outProducts.length > 0) {
                shipment.customer.warehousing && setPickedProduct({ products: outProducts });
              }
            }

            setChange((prev) => !prev);
            setChangeIN((prev) => !prev);
            setCustomerWarehousing(shipment.customer.warehousing);
            setCustomerType(shipment.customer.type.code);
            const numbersObj = [
              {
                codeName: "recipientPhoneCode",
                value: getMobileData(shipment.recipientPhone, countryCode)
              },
              {
                codeName: "recipientMobileCode",
                value: getMobileData(shipment.recipientMobile, countryCode)
              },
              {
                codeName: "senderPhoneCode",
                value: getMobileData(shipment.senderPhone, countryCode)
              },
              {
                codeName: "senderMobileCode",
                value: getMobileData(shipment.senderMobile, countryCode)
              },
            ]
            numbersObj.forEach((i) => {
              if (i.value !== null) {
                setValue(i.codeName, i.value.country.toLowerCase());
              }
            });
            setValue(
              "shipment",
              {
                ...(datePermission && { date: shipment?.date }),
                deliveryDate: shipment?.deliveryDate,
                code: shipment?.code,
                recipientName:
                  shipment?.recipientName === null
                    ? ""
                    : shipment?.recipientName,
                recipientPhone: getMobileData(shipment.recipientPhone, countryCode) !== null ? getMobileData(shipment.recipientPhone, countryCode).nationalNumber : "",
                recipientMobile: getMobileData(shipment.recipientMobile, countryCode) !== null ? getMobileData(shipment.recipientMobile, countryCode).nationalNumber : "",
                recipientAddress: shipment?.recipientAddress,
                recipientPostalCode: shipment?.recipientPostalCode,
                senderName: shipment?.senderName,
                senderPhone: getMobileData(shipment.senderPhone, countryCode) !== null ? getMobileData(shipment.senderPhone, countryCode).nationalNumber : "",
                senderMobile: getMobileData(shipment.senderMobile, countryCode) !== null ? getMobileData(shipment.senderMobile, countryCode).nationalNumber : "",
                senderAddress: shipment?.senderAddress,
                senderPostalCode: shipment?.senderPostalCode,
                packageDescription:
                  shipment?.description === null ? "" : shipment?.description,
                weight: shipment?.weight,
                piecesCount: shipment?.piecesCount,
                price: shipment?.price,
                ...(shipment?.refNumber && {
                  refNumber: shipment?.refNumber,
                }),
                ...(shipment?.returnPiecesCount && {
                  returnPiecesCount: shipment?.returnPiecesCount,
                }),
                height: shipment.size.height,
                length: shipment.size.length,
                width: shipment.size.width,
                notes: shipment?.notes === null ? "" : shipment?.notes,

                deliveryFees: shipment.deliveryFees?.toFixed(2),
                extraWeightFees: shipment.extraWeightFees?.toFixed(2),
                collectionFees: shipment.collectionFees?.toFixed(2),
                returnFees: shipment.returnFees?.toFixed(2),
                deliveryCommission: shipment.deliveryCommission?.toFixed(2),
              },
              { shouldDirty: true, shouldValidate: true }
            );

            setCreatedAtState(shipment.date);

            setEditDeliveryDate(shipment.deliveryDate)
            setDeliveryDate(shipment.deliveryDate);

            const country = shipment?.recipientZone?.branch?.country;
            const recipientZone = {
              id: shipment?.recipientZone?.id,
              name: shipment?.recipientZone?.name,
            };
            const senderZone = shipment?.senderZone;
            setAutocompleteValues({
              service: shipment?.service,
              recipientZone,
              recipientSubzone: shipment?.recipientSubzone,
              senderZone,
              senderSubzone: shipment?.senderSubzone,
              customer: shipment?.customer,
              branch: shipment?.branch,
              originBranch: shipment?.originBranch,
              transactionType: shipment?.transactionType,
              typeCode: shipment?.type,
              paymentType: shipment?.paymentType,
              ...(multiCountry && { country }),
            });

            setCalcFeesValue({
              amount: shipment.amount,
              post: shipment.postFees,
              tax: shipment.tax,
              total: shipment.totalAmount,
            });
            setCalculationChecker((prev) => ({
              customerId: true,
              serviceId: true,
              senderZoneId: true,
              senderSubzoneId: true,
              recipientZoneId: true,
              recipientSubzoneId: true,
            }));
            setCalcFeesState((prev) => ({
              ...prev,
              weight: shipment?.weight,
              price: shipment?.price,
              paymentType: shipment?.paymentType.code,
              priceType: shipment?.priceType.code,
              serviceId: parseInt(shipment.service.id),
              recipientZoneId: parseInt(shipment?.recipientZone.id),
              recipientSubzoneId: parseInt(shipment?.recipientSubzone.id),
              senderZoneId: parseInt(shipment?.senderZone.id),
              senderSubzoneId: parseInt(shipment?.senderSubzone.id),
              size: shipment?.size,
              deliveryFees: shipment.deliveryFees,
              extraWeightFees: shipment.extraWeightFees,
              collectionFees: shipment.collectionFees,
              returnFees: shipment.returnFees,
              ...(customerPermission && {
                customerId: parseInt(shipment?.customer.id),
              }),
            }));
          } else {
            setUnEditable("invalidId");
          }
        },
      }
    );

  const updateShipmentDetails = shipmentDataForUpdate?.shipment;
  const updateBranchConditionAndPaymentType =
    updateShipmentDetails?.status?.code === "PKR";
  const invalidCustomer =
    urlUpdateShipmentId &&
    (customerPermission
      ? false
      : updateShipmentDetails?.customer.id !== customerId);

  // const [zoneName, setZoneName] = useState({
  //   zone: "",
  //   subzone: "",
  // });

  const [location] = useState({
    latitude: null,
    longitude: null,
  });

  const shipmentTypeValidation = Boolean(
    watch("shipment.typeCode") === "PDP" || watch("shipment.typeCode") === "PTP"
  );

  const [saveShipment, { loading: saveLoading }] = useMutation(
    gql`
      ${SAVE_SHIPMENT_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  const firstUpdate = useRef(true);

  const skipCustomerQuery = customerPermission
    ? urlUpdateShipmentId
      ? !watch("shipment.customerId") || firstUpdate.current
      : !watch("shipment.customerId")
    : urlUpdateShipmentId;

  const [customerType, setCustomerType] = useState("");
  const { data: customerData } = useQuery(
    gql`
      ${CUSTOMER_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: skipCustomerQuery,
      variables: {
        id: customerPermission
          ? parseInt(watch("shipment.customerId"))
          : customerId,
        input: {
          ...(!user.account && {
            customerId: customerPermission
              ? parseInt(watch("shipment.customerId"))
              : customerId,
          }),
          active: true,
        },
      },
      onCompleted: (data) => {
        const customer = data.customer;
        const numbersObj = [
          {
            name: "shipment.senderMobile",
            codeName: "senderMobileCode",
            value: getMobileData(customer?.mobile, countryCode)
          },
          {
            name: "shipment.senderPhone",
            codeName: "senderPhoneCode",
            value: getMobileData(customer?.phone, countryCode)
          },
        ]
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });

        setCustomerType(customer.type.code);
        setCustomerWarehousing(customer.warehousing);
        customer.address &&
          setValue("shipment.senderAddress", customer.address, {
            shouldValidate: true,
          });
        setValue("shipment.senderName", customer.name, {
          shouldValidate: true,
        });
        customer.defaultOpenable &&
          setValue("shipment.openable", customer.defaultOpenable.code, {
            shouldValidate: true,
          });
        customer.defaultPriceType &&
          setValue("shipment.priceType", customer.defaultPriceType.code, {
            shouldValidate: true,
          });
        customer.activityName &&
          setValue("shipment.packageDescription", customer.activityName, {
            shouldValidate: true,
          });
        customer.postalCode &&
          setValue("shipment.senderPostalCode", customer.postalCode, {
            shouldValidate: true,
          });

        const paymentTypeValidation = Boolean(
          customer.paymentTypes?.code === "CASH" ||
          customer.paymentTypes?.code === "CRDT"
        );

        setCalcFeesState((prev) => ({
          ...prev,
          ...(customer.paymentTypes[0]?.code && {
            paymentType: customer.paymentTypes[0]?.code,
          }),
          ...(customer.defaultPriceType?.code && {
            priceType: customer.defaultPriceType?.code,
          }),
          ...(paymentTypeValidation && {
            price: 0,
          }),
        }));

        if (paymentTypeValidation) {
          setValue("shipment.price", 0);
        }
      },
    }
  );

  const keys = localStorage.getItem("shipments") ? JSON.parse(localStorage.getItem("shipments")) : initialData.shipments
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const countryId =
    customerData?.customer?.zone?.branch?.country?.id ??
    updateShipmentDetails?.senderZone?.branch?.country?.id;

  const codePermission =
    user.hasPermission("shipping.shipment.specify_code") &&
    (!user.account || user.account.specifyShipmentCode);

  const datePermission = user.hasPermission("shipping.shipment.specify_Date");
  const CRDTShipment = Boolean(watch("shipment.paymentType") === "CRDT");

  const allowWarehousing = warehousingSystem && customerWarehousing;

  const newShipment = urlUpdateShipmentId
    ? updateBranchConditionAndPaymentType
    : true;
  const receiveInWarehouseStatusValid =
    updateShipmentDetails?.transactions[0]?.shipmentStatus?.code !== "RITS" && updateShipmentDetails?.transactions[0]?.shipmentStatus?.code !== "PRP"

  const ShowReceiveInWareHouse =
    newShipment &&
    (watch("shipment.typeCode") === "FDP" ||
      watch("shipment.typeCode") === "PDP" ||
      watch("shipment.typeCode") === "PTP") &&
    watch("shipment.paymentType") !== "CASH" &&
    user.hasPermission(
      GetManifestPermissionSlug("RITS", "create").toLowerCase()
    ) &&
    receiveInWarehouseStatusValid;

  const [shipmentId, setShipmentId] = React.useState(0);
  const [openPrint, setOpenPrint] = React.useState(false);
  const [customerAfterSave, setCustomerAfterSave] = React.useState();

  const defaultPrint = Globals.settings.waybillCode;

  const afterSaveAndPrint = (customer, shipmentIdPrint) => {
    if (updateShipmentDetails) {
      pushUrl(
        props,
        `/admin/shipments/${shipmentId ? shipmentId : shipmentIdPrint}`
      );
      return;
    }
    props.reset(customerAfterSave ? customerAfterSave : customer);
    !anotherShipment && closeDialog();
  };
  const handleOnSubmitForm = (data) => {
    const shipment = data.shipment;
    if (Globals.settings.allowPhoneKey) {
      const checkRecipientMobile = validNumber(shipment.recipientMobile, data.recipientMobileCode)
      const checkRecipientPhone = validNumber(shipment.recipientPhone, data.recipientPhoneCode)
      const checkRenderMobile = validNumber(shipment.senderMobile, data.senderMobileCode)
      const checkRenderPhone = validNumber(shipment.senderPhone, data.senderPhoneCode)
      let names = [
        {
          name: "shipment.recipientMobile",
          validate: checkRecipientMobile.valid,
          message: checkRecipientMobile.message,
        },
        {
          name: "shipment.recipientPhone",
          validate: checkRecipientPhone.valid,
          message: checkRecipientPhone.message,
        },
        {
          name: "shipment.senderMobile",
          validate: checkRenderMobile.valid,
          message: checkRenderMobile.message,
        },
        {
          name: "shipment.senderPhone",
          validate: checkRenderPhone.valid,
          message: checkRenderPhone.message,
        },
      ];
      if (!checkRecipientMobile.valid || !checkRecipientPhone.valid || !checkRenderMobile.valid || !checkRenderPhone.valid) {
        names.map((e) => !e.validate && setError(e.name, { type: 'custom', message: t(e.message) }))
        return
      }
      shipment.recipientMobile = getFormatNumber(shipment.recipientMobile, data.recipientMobileCode)
      shipment.recipientPhone = getFormatNumber(shipment.recipientPhone, data.recipientPhoneCode)
      shipment.senderMobile = getFormatNumber(shipment.senderMobile, data.senderMobileCode)
      shipment.senderPhone = getFormatNumber(shipment.senderPhone, data.senderPhoneCode)
    }

    const pickedProductsOut = pickedProduct.products;
    const pickedProductsIN = pickedProductIN?.products;
    const product = [];
    if (showProductsTablePTP || showProductsTableFDP) {
      for (let i = 0; i < pickedProductsOut.length; i++) {
        const referenceBranch = {
          productId: parseInt(pickedProductsOut[i].product.id),
          quantity: parseInt(pickedProductsOut[i].quantity),
          price: CRDTShipment ? 0 : parseFloat(pickedProductsOut[i].price),
          ...(showProductsTablePTP && { typeCode: "OUT" })
        };
        product.push(referenceBranch);
      }

    }
    if (showProductsTablePTP || showProductsTableRTS) {
      for (let i = 0; i < pickedProductsIN.length; i++) {
        const referenceBranch = {
          productId: parseInt(pickedProductsIN[i].product.id),
          quantity: parseInt(pickedProductsIN[i].quantity),
          price: CRDTShipment ? 0 : parseFloat(pickedProductsIN[i].price),
          ...(showProductsTablePTP && { typeCode: "IN" })
        };
        product.push(referenceBranch);
      }
    }
    const branches = {
      branchId:
        shipment.typeCode === "RTS" ||
          Boolean(allowWarehousing && watch("shipment.typeCode") === "FDP")
          ? shipment.branchId
          : shipment.originBranchId,
      originBranchId: shipment.originBranchId,
    };

    const date = dateTimeFormat(shipment["date"]);
    const deliveryDate = shipment["deliveryDate"] && dateFormat(shipment["deliveryDate"]);

    saveShipment({
      variables: {
        input: {
          ...(datePermission && { date }),
          ...(deliveryDatePermission && { deliveryDate: deliveryDate ?? null }),
          ...(updateShipmentDetails && { id: updateShipmentDetails?.id }),
          ...(codePermission && { code: shipment.code?.trim() }),

          ...(updateShipmentDetails
            ? updateBranchConditionAndPaymentType && branches
            : branches),

          transactionTypeId: shipment.transactionTypeId,
          ...(forcePostalCode && {
            senderPostalCode: shipment.senderPostalCode,
          }),
          ...(forcePostalCode && {
            recipientPostalCode: shipment.recipientPostalCode,
          }),

          recipientName: shipment.recipientName,
          ...(ShowReceiveInWareHouse && {
            receiveInWarehouse: shipment.receiveInWarehouse,
          }),
          paymentTypeCode: shipment.paymentType,
          priceTypeCode: shipment.priceType,
          recipientZoneId: shipment.recipientZoneId,
          recipientSubzoneId: shipment.recipientSubzoneId,
          recipientPhone: shipment.recipientPhone,
          recipientMobile: shipment.recipientMobile,
          recipientAddress: shipment.recipientAddress,
          senderName: shipment.senderName,
          senderPhone: shipment.senderPhone,
          senderMobile: shipment.senderMobile,
          senderZoneId: shipment.senderZoneId,
          senderSubzoneId: shipment.senderSubzoneId,
          senderAddress: shipment.senderAddress,
          description: shipment.packageDescription,
          ...(warehousingSystem
            ? productsSelectedLength && productsSelectedLengthIN && { weight: parseFloat(shipment.weight) }
            : { weight: parseFloat(shipment.weight) }),
          ...(warehousingSystem
            ? productsSelectedLength && productsSelectedLengthIN && {
              piecesCount: parseFloat(shipment.piecesCount),
            }
            : { piecesCount: parseFloat(shipment.piecesCount) }),
          ...(warehousingSystem
            ? productsSelectedLength && { price: parseFloat(shipment.price) }
            : { price: parseFloat(shipment.price) }),
          ...(overwriteFees && {
            deliveryFees: parseFloat(shipment.deliveryFees),
            extraWeightFees: parseFloat(shipment.extraWeightFees),
            returnFees: parseFloat(shipment.returnFees),
            deliveryCommission: parseFloat(shipment.deliveryCommission),
            collectionFees: parseFloat(shipment.collectionFees),
          }),
          notes: shipment.notes,
          refNumber: shipment.refNumber,
          typeCode: shipment.typeCode,
          ...(shipment.returnPiecesCount &&
            shipmentTypeValidation && {
            returnPiecesCount: parseFloat(shipment.returnPiecesCount),
          }),
          openableCode: shipment.openable,
          serviceId: parseInt(shipment.serviceId),
          ...(shipment.customerId && {
            customerId: parseInt(shipment.customerId),
          }),
          size: {
            length: parseInt(shipment.length),
            height: parseInt(shipment.height),
            width: parseInt(shipment.width),
          },
          ...(location.latitude && { latitude: location.latitude }),
          ...(location.longitude && { longitude: location.longitude }),
          ...(allowWarehousing &&
            (!productsSelectedLength || !productsSelectedLengthIN) && { shipmentProducts: product }),
        },
      },
    })
      .then((res) => {
        const action = () => (
          <Button
            href={`/admin/shipments/${res?.data?.saveShipment?.id}`}
            sx={[
              { color: "white", fontWeight: "bold" },
              {
                "&:hover": {
                  color: "white",
                },
              },
            ]}
            onClick={(e) => {
              e.preventDefault();
              pushUrl(props, `/admin/shipments/${res?.data?.saveShipment?.id}`);
            }}
          >
            {t("view")}
          </Button>
        );

        enqueueSnackbar(
          t("shipmentSaved", {
            code: res?.data?.saveShipment?.code,
          }),
          {
            variant: "success",
            action,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          }
        );
        if (document.location.pathname.endsWith("shipments")) {
          client.refetchQueries({
            include: [
              gql`
                ${SEARCH(defaultQueryFields(keys, adminNotesPermission)).query}
              `,
            ],
          });
          const shipmentsSumQuery = keys && getShipmentsSumQuery(keys)
          const sumShipmentsSkip = keys && shipmentsSumQuery.queryFields.length === 0
          !sumShipmentsSkip && client.refetchQueries({
            include: [
              gql`
                ${SUM_SHIPMENT(shipmentsSumQuery?.queryFields).query}
              `,
            ],
          });
        }
        if (updateShipmentDetails) {
          if (parseInt(saveType) === 1) {
            setShipmentId(res?.data?.saveShipment?.id);
            defaultPrint
              ? printLink(
                defaultPrint,
                false,
                res?.data?.saveShipment?.id,
                () =>
                  afterSaveAndPrint(
                    res?.data?.saveShipment?.customer,
                    res?.data?.saveShipment?.id
                  )
              )
              : setOpenPrint(true);
          }
          parseInt(saveType) !== 1 &&
            pushUrl(props, `/admin/shipments/${res?.data?.saveShipment?.id}`);
          return;
        }
        parseInt(saveType) !== 1 &&
          props.reset({ ...res?.data?.saveShipment?.customer });
        if (parseInt(saveType) === 1) {
          setShipmentId(res?.data?.saveShipment?.id);
          setCustomerAfterSave({ ...res?.data?.saveShipment?.customer });
          defaultPrint
            ? printLink(defaultPrint, false, res?.data?.saveShipment?.id, () =>
              afterSaveAndPrint(res?.data?.saveShipment?.customer)
            )
            : setOpenPrint(true);
        }
        if (!anotherShipment) {
          parseInt(saveType) !== 1 && closeDialog();
        }
      })
      .catch(({ graphQLErrors }) => {
        // console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError, "shipment.", true);
        if (!graphQLErrors[0]["extensions"]?.validation)
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
      });
  };

  const validateCalcFeesField = (name) => calcFeesState[name] !== null;
  const canCalculate = () =>
    validateCalcFeesField("recipientSubzoneId") &&
    validateCalcFeesField("recipientZoneId") &&
    validateCalcFeesField("senderSubzoneId") &&
    validateCalcFeesField("senderZoneId") &&
    validateCalcFeesField("priceType") &&
    validateCalcFeesField("paymentType") &&
    validateCalcFeesField("price") &&
    validateCalcFeesField("weight") &&
    calculationChecker?.["recipientSubzoneId"] &&
    calculationChecker?.["recipientZoneId"] &&
    calculationChecker?.["senderSubzoneId"] &&
    calculationChecker?.["senderZoneId"] &&
    calculationChecker?.["serviceId"] &&
    calcFeesState["size"]["width"] !== null &&
    calcFeesState["size"]["height"] !== null &&
    calcFeesState["size"]["length"] !== null &&
    (customerPermission
      ? calculationChecker?.["customerId"] &&
      validateCalcFeesField("customerId")
      : true);

  //////////////////////// QUERY CALCULATE FEES //////////////////////////////
  const { loading: claculateFeesLoading, data: calcFeesData } = useQuery(
    gql`
      ${CALCULATE_FEES_QUERY.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: calcFeesState.render,
      fetchPolicy: "no-cache",
      skip:
        !canCalculate() ||
        invalidCustomer ||
        firstCalcUpdate.current ||
        !calcFeesState?.serviceId,
      variables: {
        input: {
          weight: calcFeesState && parseFloat(calcFeesState?.weight),
          price: calcFeesState && parseFloat(calcFeesState?.price),
          priceTypeCode: calcFeesState?.priceType,
          paymentTypeCode: calcFeesState?.paymentType,
          serviceId: calcFeesState?.serviceId,
          recipientZoneId: calcFeesState?.recipientZoneId,
          recipientSubzoneId: calcFeesState?.recipientSubzoneId,
          // senderZoneId: calcFeesState?.senderZoneId,
          ...(calcFeesState?.senderZoneId && {
            senderZoneId: calcFeesState?.senderZoneId,
          }),
          senderSubzoneId: calcFeesState?.senderSubzoneId,
          ...(calcFeesState?.size && { size: calcFeesState?.size }),

          ...(calcFeesState?.deliveryFees !== null && {
            deliveryFees: calcFeesState?.deliveryFees,
          }),
          ...(calcFeesState?.extraWeightFees !== null && {
            extraWeightFees: calcFeesState?.extraWeightFees,
          }),
          ...(calcFeesState?.returnFees !== null && {
            returnFees: calcFeesState?.returnFees,
          }),
          ...(calcFeesState?.collectionFees !== null && {
            collectionFees: calcFeesState?.collectionFees,
          }),
          ...(customerPermission && { customerId: calcFeesState?.customerId }),
        },
      },
      onCompleted: (data) => {
        const shippingFees = data.calculateShipmentFees;
        setValue(
          "shipment.deliveryFees",
          parseFloat(shippingFees.delivery?.toFixed(2))
        );
        setValue(
          "shipment.extraWeightFees",
          parseFloat(shippingFees.weight?.toFixed(2))
        );
        setValue(
          "shipment.returnFees",
          parseFloat(shippingFees.return?.toFixed(2))
        );
        setValue(
          "shipment.collectionFees",
          parseFloat(shippingFees.collection?.toFixed(2))
        );
        setCalcFeesValue({
          amount: parseFloat(shippingFees.amount?.toFixed(2)),
          post: parseFloat(shippingFees.post?.toFixed(2)),
          tax: parseFloat(shippingFees.tax?.toFixed(2)),
          total: parseFloat(shippingFees.total?.toFixed(2)),
        });
      },
    }
  );

  function currentValueMatchingNewValues(data, fieldName) {
    const currentValue = watch(`shipment.${fieldName}`);

    if (typeof currentValue === "number") {
      const isValueValid = selectedIdIsValid(data, currentValue);

      if (isValueValid) {
        setCalculationChecker((prev) => ({ ...prev, [fieldName]: true }));
      } else {
        calcFeesHandler({ value: null, name: fieldName });

        setValue(`shipment.${fieldName}`, "");
      }
    }
  }

  const [disPaymentType, setDisPaymentType] = useState(true);
  const onCompletePaymentType = (data) => {
    const currentValue = watch(`shipment.paymentType`);
    const typeData = data["listPaymentTypesDropdown"];
    const isValid = (list, code) => list.some((i) => i.code === code);

    if (typeData.length > 0) {
      const isValueNotValid = !isValid(typeData, currentValue);

      if (isValueNotValid) {
        setValue(`shipment.paymentType`, "");
      }
    }

    const shipmentTypes = data[Object.keys(data)[0]];
    if (!updateShipmentDetails) {
      const defaultType = data.listPaymentTypesDropdown[0];

      const value = isValid(shipmentTypes, defaultType?.code)
        ? defaultType
        : shipmentTypes[0];
      setValue("shipment.paymentType", value.code, {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (value.code === "CASH" || value.code === "CRDT") {
        setValue("shipment.price", 0);
        setValue("shipment.priceType", "INCLD");
        calcFeesHandler({ value: 0, name: "price" });
        calcFeesHandler({
          value: "INCLD",
          name: "priceType",
        });
      }
      calcFeesHandler({ value: value.code, name: "paymentType" });
      setAutocompleteValues((prev) => ({
        ...prev,
        paymentType: { ...value },
      }));
    }
  };
  const onCompletePriceType = (data) => {
    setDisPaymentType(false);
    if (updateShipmentDetails) {
      lookupCodeIsValid(data, updateShipmentDetails?.priceType?.code) &&
        setValue("shipment.priceType", updateShipmentDetails?.priceType?.code, {
          shouldDirty: true,
          shouldValidate: true,
        });
    } else {
      const defaultPrice = customerData?.customer?.defaultPriceType?.code;

      const value = lookupCodeIsValid(data, defaultPrice)
        ? defaultPrice
        : data?.listLookupEntriesDropdown[0].code;
      setValue("shipment.priceType", value, {
        shouldDirty: true,
        shouldValidate: true,
      });

      calcFeesHandler({ value: value, name: "priceType" });
    }
  };

  const onOpenable = (data) => {
    if (updateShipmentDetails) {
      setValue("shipment.openable", updateShipmentDetails?.openable?.code, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue("shipment.openable", "N", {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const [transactionData, setTransactionData] = useState([]);

  const parseData = (data) => {
    return data;
  };

  // TODO : solve state error , cant set state while render another state
  const transactionDataLoaded = (data) => {
    return data;
  };

  const onCompleteShipmentType = (data) => {
    const currentValue = watch(`shipment.typeCode`);
    const typeData = data["listShipmentTypesDropdown"];
    const isValid = (list, code) => list.some((i) => i.code === code);

    if (typeData.length > 0) {
      const isValueNotValid = !isValid(typeData, currentValue);

      if (isValueNotValid) {
        setValue(`shipment.typeCode`, "");
      }
    }

    const shipmentTypes = data[Object.keys(data)[0]];
    if (!updateShipmentDetails) {
      const defaultType = customerData?.customer?.defaultShipmentType;

      const value = isValid(shipmentTypes, defaultType?.code)
        ? defaultType
        : shipmentTypes[0];
      setAutocompleteValues((prev) => ({
        ...prev,
        typeCode: { ...value },
      }));
    }
  };

  const onCompleteService = (data, name) => {
    currentValueMatchingNewValues(data, "serviceId");


    const shippingSettingsId = settings?.shippingSettings?.["defaultShippingService"]?.id;
    const shippingSettingsInListDropdown =
      shippingSettingsId ? data.listShippingServicesDropdown.find(i => i.id === shippingSettingsId) : null


    const defaultService = shippingSettingsInListDropdown ?? data.listShippingServicesDropdown[0];

    // const defaultService =
    //   settings?.shippingSettings?.["defaultShippingService"] ??
    //   data.listShippingServicesDropdown[0];

    const valid = selectedIdIsValid(data, defaultService?.id);

    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        service: { ...defaultService },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: true,
      }));

      calcFeesHandler({ value: defaultService?.id, name: "serviceId" });
    }
  };

  const onCompleteZones = (data, name) => {
    currentValueMatchingNewValues(data, "senderZoneId");

    const defaultZone = customerData?.customer?.zone;

    const valid = selectedIdIsValid(data, defaultZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        senderZone: { ...defaultZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: true,
      }));
      calcFeesHandler({ value: defaultZone?.id, name: "senderZoneId" });
    }
  };

  const onCompleteSubzones = (data, name) => {
    currentValueMatchingNewValues(data, "senderSubzoneId");

    const defaultSubZone = customerData?.customer?.subzone;

    const valid = selectedIdIsValid(data, defaultSubZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        senderSubzone: { ...defaultSubZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: true,
      }));
      calcFeesHandler({ value: defaultSubZone?.id, name: "senderSubzoneId" });
    }
  };

  const onChangeSenderSubzone = (e) => {
    const value = e?.id;
    setRecipientZones(null);
    setCalculationChecker((prev) => ({
      customerId: prev.customerId,
      serviceId: prev.serviceId,
      senderZoneId: prev.senderZoneId,
      senderSubzoneId: true,
    }));

    calcFeesHandler({ value: value, name: "senderSubzoneId" });
  };

  const onCompleteRecipientZones = (data, name) => {
    currentValueMatchingNewValues(data, "recipientZoneId");

    const defaultZone = recipientZones?.zone;

    setRecipientZonesList((prev) => ({
      ...prev,
      zonesList: data.listZonesDropdown,
    }));

    const valid = selectedIdIsValid(data, defaultZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientZone: { ...defaultZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: true,
      }));
      calcFeesHandler({ value: defaultZone?.id, name: "recipientZoneId" });
    }
  };

  const onCompleteRecipientSubzones = (data, name) => {
    currentValueMatchingNewValues(data, "recipientSubzoneId");

    const defaultSubZone = recipientZones?.subzone;

    setRecipientZonesList((prev) => ({
      ...prev,
      subzonesList: data.listZonesDropdown,
    }));

    const valid = selectedIdIsValid(data, defaultSubZone?.id);
    if (valid) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientSubzone: { ...defaultSubZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: prev.recipientZoneId,
        recipientSubzoneId: true,
      }));
      calcFeesHandler({
        value: defaultSubZone?.id,
        name: "recipientSubzoneId",
      });
    }
  };

  ///////////////// recipient popover functions ///////////////////////
  const [anchorElPopover, setAnchorElPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
    setRecipientZones(null);
    setCalculationChecker((prev) => ({
      customerId: prev.customerId,
      serviceId: prev.serviceId,
      senderZoneId: prev.senderZoneId,
      senderSubzoneId: prev.senderSubzoneId,
      recipientZoneId: prev.recipientZoneId,
      recipientSubzoneId: false,
    }));
    calcFeesHandler({
      value: "",
      name: "recipientSubzoneId",
      ...(updateShipmentDetails && { feesAvailable: true }),
    });
    setValue("shipment.recipientSubzoneId", "");
  };

  const handleCloseRecipentPopover = () => {
    setAnchorElPopover(null);
  };
  const openPopover = Boolean(anchorElPopover);

  const recipientPopoverId = openPopover ? "recipient-popover-id" : undefined;

  const checkValidity = (list, value) => list?.some((i) => i.id === value);

  const onComleteRecipientDetails = (data) => {
    const recipientData = data?.customer ?? data?.consignee;
    const numbersObj = [
      {
        name: "shipment.recipientMobile",
        codeName: "recipientMobileCode",
        value: getMobileData(recipientData?.mobile, countryCode)
      },
      {
        name: "shipment.recipientPhone",
        codeName: "recipientPhoneCode",
        value: getMobileData(recipientData?.phone, countryCode)
      },
    ]
    numbersObj.forEach((i) => {
      if (i.value !== null) {
        setValue(i.codeName, i.value.country.toLowerCase());
        setValue(i.name, i.value.nationalNumber);
      }
    });

    recipientData.address &&
      setValue("shipment.recipientAddress", recipientData.address, {
        shouldValidate: true,
      });
    setValue("shipment.recipientName", recipientData.name ?? "", {
      shouldValidate: true,
    });
    setRecipientZones({
      zone: recipientData.zone,
      subzone: recipientData.subzone,
    });

    const zoneValid = checkValidity(
      recipientZonesList.zonesList,
      recipientData.zone.id
    );

    const subzoneValid = checkValidity(
      recipientZonesList.subzonesList,
      recipientData.subzone.id
    );

    if (zoneValid) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientZone: { ...recipientData.zone },
      }));
      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: true,
      }));
      calcFeesHandler({
        value: recipientData.zone.id,
        name: "recipientZoneId",
        ...(updateShipmentDetails && { feesAvailable: true }),
      });
    }

    if (subzoneValid) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientSubzone: { ...recipientData.subzone },
      }));
      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: prev.recipientZoneId,
        recipientSubzoneId: true,
      }));
      calcFeesHandler({
        value: recipientData.subzone.id,
        name: "recipientSubzoneId",
        ...(updateShipmentDetails && { feesAvailable: true }),
      });
    }
  };
  ///////////////// End recipient popover functions ///////////////////////

  const invalidId = (
    <Grid container justifyContent="center" className={classes.track}>
      <EmptyTableMessage
        loading={shipmentDataForUpdateLoading}
        message={t("noShipmentWithCode")}
      />
    </Grid>
  );

  const showPrice = Boolean(
    watch("shipment.paymentType") !== "CRDT" &&
    watch("shipment.paymentType") !== "CASH"
  );
  const showProductsTablePTP =
    allowWarehousing &&
    watch("shipment.typeCode") === "PTP" &&
    ["COLC", "VISA", "CRDT"].includes(watch("shipment.paymentType"));

  const [pickedProduct, setPickedProduct] = useState({ products: [] });

  const [pickedProductIN, setPickedProductIN] = useState({ products: [] });
  const [change, setChange] = useState(false);
  const [changeIN, setChangeIN] = useState(false);
  const [changeDes, setChangeDes] = useState(false);
  const productsSelected = pickedProduct.products;
  const productsSelectedIN = pickedProductIN.products;
  const productsSelectedLength = productsSelected.length === 0;
  const productsSelectedLengthIN = productsSelectedIN.length === 0;

  const disableSubmitButton =
    unEditable ||
    saveLoading ||
    claculateFeesLoading ||
    (showProductsTablePTP ? (!productsSelectedLength && productsSelectedLengthIN) : false) ||
    (showProductsTablePTP ? (productsSelectedLength && !productsSelectedLengthIN) : false)
    || (!calcFeesData && !updateShipmentDetails);

  // product

  const showProductsTableRTS = allowWarehousing && watch("shipment.typeCode") === "RTS" &&
    ["COLC"].includes(watch("shipment.paymentType"));

  const showProductsTableFDP =
    allowWarehousing &&
    watch("shipment.typeCode") === "FDP" &&
    ["COLC", "VISA", "CRDT"].includes(watch("shipment.paymentType"));

  const setSelect = (obj) => {
    setPickedProduct((prev) => {
      const seen = new Set();
      const duplicateId = [];
      const products = [...prev.products, ...obj];
      const filteredProduct = products.filter((el) => {
        if (!el.quantity) {
          el.product.quantity = 1;
          el.quantity = 1;
          el.price = el.product.price;
        }
        const duplicate = seen.has(el.product.id);
        if (duplicate) {
          duplicateId.push(el.product.id);
        }
        seen.add(el.product.id);
        return !duplicate;
      });
      for (let i = 0; i < duplicateId.length; i++) {
        for (let j = 0; j < filteredProduct.length; j++) {
          if (duplicateId[i] === filteredProduct[j].product.id) {
            filteredProduct[j].quantity =
              Number(filteredProduct[j].quantity) + 1;
          }
        }
      }
      return { products: filteredProduct };
    });
    setChangeDes(true);
    setChange((prev) => !prev);
  };

  const setSelectIN = (obj) => {
    setPickedProductIN((prev) => {
      const seen = new Set();
      const duplicateId = [];
      const products = [...prev.products, ...obj];
      const filteredProduct = products.filter((el) => {
        if (!el.quantity) {
          el.product.quantity = 1;
          el.quantity = 1;
          el.price = el.product.price;
        }
        const duplicate = seen.has(el.product.id);
        if (duplicate) {
          duplicateId.push(el.product.id);
        }
        seen.add(el.product.id);
        return !duplicate;
      });
      for (let i = 0; i < duplicateId.length; i++) {
        for (let j = 0; j < filteredProduct.length; j++) {
          if (duplicateId[i] === filteredProduct[j].product.id) {
            filteredProduct[j].quantity =
              Number(filteredProduct[j].quantity) + 1;
          }
        }
      }
      return { products: filteredProduct };
    });
    setChangeDes(true);

    setChangeIN((prev) => !prev);
  };
  const screenWidth = useWidth();
  const [state, setState] = useState({
    productList: {
      left: false,
      bottom: false,
      right: false,
    },
    productListIN: {
      left: false,
      bottom: false,
      right: false,
    },
    manifestList: {
      left: false,
      bottom: false,
      right: false,
    },
  });

  const productDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      productList: { ...prev.productList, [anchor]: open },
    }));
  };
  const productDrawerHandlerIN = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      productListIN: { ...prev.productListIN, [anchor]: open },
    }));
  };
  const anchor = screenWidth === "xs" ? "bottom" : "right";
  useEffect(() => {
    if (!productsSelectedLength) {
      let totalPrice = 0;
      let totalQuantity = 0;
      let totalWeight = 0;
      productsSelected.forEach((product, index) => {
        setValue(`shipment.shipmentProducts${index}price`, product.price);
        setValue(`shipment.shipmentProducts${index}quantity`, product.quantity);
        totalPrice +=
          Number(watch(`shipment.shipmentProducts${index}price`)) *
          Number(watch(`shipment.shipmentProducts${index}quantity`));
        totalQuantity += Number(
          watch(`shipment.shipmentProducts${index}quantity`)
        );
        totalWeight +=
          Number(product.product.weight) *
          Number(watch(`shipment.shipmentProducts${index}quantity`));
      });
      !CRDTShipment
        ? setValue("shipment.price", totalPrice)
        : setValue("shipment.price", 0);
      setValue("shipment.weight", totalWeight);
      setValue("shipment.piecesCount", totalQuantity);
      if (productsSelectedLengthIN) {
        setValue("shipment.returnPiecesCount", 0);
      }
      setTotalprice(CRDTShipment ? 0 : totalPrice, totalWeight);
      changeDes && setDesciption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  useEffect(() => {
    if (!productsSelectedLengthIN) {
      let totalQuantity = 0;
      let totalPrice = 0;
      let totalWeight = 0;

      productsSelectedIN.forEach((product, index) => {
        setValue(`shipment.shipmentProducts${index}priceIN`, product.price);
        setValue(`shipment.shipmentProducts${index}quantityIN`, product.quantity);
        totalPrice +=
          Number(watch(`shipment.shipmentProducts${index}priceIN`)) *
          Number(watch(`shipment.shipmentProducts${index}quantityIN`));
        totalQuantity += Number(
          watch(`shipment.shipmentProducts${index}quantityIN`)
        );
        totalWeight +=
          Number(product.product.weight) *
          Number(watch(`shipment.shipmentProducts${index}quantityIN`));
      });

      if (showProductsTableRTS) {
        setValue("shipment.price", -totalPrice)
        changeDes && setDesciption(true);
        setValue("shipment.piecesCount", totalQuantity);
        setValue("shipment.weight", totalWeight);
        setTotalprice(-totalPrice, totalWeight);
      }

      setValue("shipment.returnPiecesCount", totalQuantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeIN, change]);

  const setTotalprice = (totalPrice, totalWeight) => {
    clearTimeout(priceTimer);
    priceTimer = setTimeout(() => {
      calcFeesHandler({
        value: totalPrice,
        name: "price",
      });
    }, 1000);
    clearTimeout(weightTimer);
    weightTimer = setTimeout(() => {
      calcFeesHandler({
        value: parseFloat(totalWeight),
        name: "weight",
      });
    }, 1000);
  };

  const setDesciption = (IN) => {
    let des = "";
    (IN ? productsSelectedIN : productsSelected).forEach((product) => {
      des += product.quantity + " " + product.product.name + "\n";
    });
    setValue("shipment.packageDescription", des);
  };

  const EmptyTextFields = () => {
    setValue("shipment.price", 0);
    setValue("shipment.weight", 1);
    // calcFeesHandler({ value: 1, name: "weight" });
    // calcFeesHandler({ value: 0, name: "price" });
    setTotalprice(0, 1);
    setValue("shipment.piecesCount", 1);
    setValue("shipment.packageDescription", "");
    setPickedProduct({ products: [] });
  };
  const EmptyFieldsChangeType = () => {
    setPickedProduct({ products: [] });
  };
  //
  const EmptyTextFieldsIN = () => {
    if (showProductsTableRTS) {
      setValue("shipment.price", 0);
      setValue("shipment.weight", 1);
      // calcFeesHandler({ value: 1, name: "weight" });
      // calcFeesHandler({ value: 0, name: "price" });
      setTotalprice(0, 1);
      setValue("shipment.piecesCount", 1);
      setValue("shipment.packageDescription", "");
    } else {
      setValue("shipment.returnPiecesCount", 1);
    }
    setPickedProductIN({ products: [] });
  };
  const screenIsSmall = isWidthDown("xs", screenWidth);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [saveType, setSaveType] = React.useState(
    localStorage.getItem("saveType") ?? 0
  );

  const handleMenuItemClick = (event, index) => {
    localStorage.setItem("saveType", index);
    setSaveType(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const savButton = (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        disabled={disableSubmitButton}
      >
        <Button type="submit">
          {saveLoading ? <ButtonLoading /> : options[saveType]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 20,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === saveType}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  const [code, setCode] = useState("");
  useQuery(
    gql`
      ${BARCODE.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !code,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        code: code,
      },
      onCompleted: (data) => {
        if (!data.barcode) {
          enqueueSnackbar(t("noBarcode"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          return false;
        }
        const change =
          watch("shipment.customerId") &&
          parseInt(watch("shipment.customerId")) === data.barcode.customer.id;
        !change &&
          setAutocompleteValues((prev) => ({
            ...prev,
            //this spreed object to render field when change customer
            customer: data.barcode.customer,
          }));
        !change && customerChange(data.barcode.customer);
        setCode("");
      },
    }
  );
  const getCustomer = () => {
    setCode(watch("shipment.code"));
  };
  const customerChange = (e) => {
    const value = e?.id;
    firstUpdate.current = false;
    setCustomerWarehousing(false);
    setRecipientZones(null);
    setCalculationChecker({ customerId: true });
    calcFeesHandler({
      value: value,
      name: "customerId",
    });
    // pickedProductIN.products > 0 && EmptyTextFields();

    const fieldsName = [
      "senderMobile",
      "senderPhone",
      "senderAddress",
      "senderName",
    ];
    fieldsName.forEach((i) => {
      setValue(`shipment.${i}`, "");
    });
  };

  const customerProductData = (e) => {
    const value = e?.id;
    const shipment = shipmentDataForUpdate?.shipment;
    if (value === shipment?.customer.id) {
      if (shipment?.shipmentProducts) {
        const inProducts = shipment.shipmentProducts.filter(product => product.type === "IN");
        const outProducts = shipment.shipmentProducts.filter(product => product.type === "OUT");
        if (inProducts.length > 0) {
          shipment.customer.warehousing && setPickedProductIN({ products: inProducts });
        }
        if (outProducts.length > 0) {
          shipment.customer.warehousing && setPickedProduct({ products: outProducts });
        }
      }
    } else {
      EmptyTextFieldsIN()
      EmptyFieldsChangeType()
    }
  };
  return (
    <Root>
      {shipmentDataForUpdateLoading || unEditable === "invalidId" ? (
        invalidId
      ) : unEditable ? (
        <Can permission="uneEditable" />
      ) : (
        <div className={classes.shipmentRoot}>
          {props.children}

          <PrintComponent
            oneShipment
            hidden={["3d"]}
            shipmentsId={shipmentId}
            openPrint={openPrint}
            setOpenPrint={setOpenPrint}
            afterSaveFun={afterSaveAndPrint}
            printType={"R&P"}
          />
          {/* <PrintComponent
            shipmentsId={shipmentId}
            oneShipment
            hidden={["3d"]}
            openPrintDialogAfterSave={shipmentId}
            type={"saveShipment"}
            afterSave={afterSaveAndPrint}
            anotherShipment={anotherShipment}
          /> */}

          <Dialog
            fullScreen
            open={openMapDialogRecipient || openMapDialogSender}
            onClose={handleCloseMap}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseMap}
                  aria-label="close"
                  size="large"
                >
                  <Close />
                </IconButton>
                <Typography
                  color="inherit"
                  variant="h6"
                  className={classes.title}
                >
                  {t("map")}
                </Typography>
                <Button autoFocus color="inherit" onClick={() => {
                  addAddress();
                  handleCloseMap();
                }} >
                  {t("save")}
                </Button>
              </Toolbar>
            </AppBar>
            {/* <Maps
              zoneName={zoneName}
              location={(latitude, longitude) => {
                setLocation({
                  ...location,
                  latitude: latitude,
                  longitude: longitude,
                });
              }}
            /> */}
            <MapComponent setLocationValue={setLocationValue} heightValue={"100vh"} />
          </Dialog>
          <Dialog
            anchor={anchor}
            fullWidth
            maxWidth="md"
            open={state.productList[anchor] ? state.productList[anchor] : state.productListIN[anchor]}
            onClose={() => { state.productList[anchor] ? productDrawerHandler(anchor, false) : productDrawerHandlerIN(anchor, false) }}
            className={classes.dialog}
          >
            <DialogContent style={{ overflow: "hidden", padding: 0 }}>
              <ProductList
                setSelected={(obj) => {
                  state.productList[anchor] ? setSelect(obj) : setSelectIN(obj);
                }}
                filter={state.productList[anchor] ? false : true}
                type={"PADD"}
                selected={productsSelected}
                done={() => { state.productList[anchor] ? productDrawerHandler(anchor, false) : productDrawerHandlerIN(anchor, false) }}
                variables={customerId}
              />
            </DialogContent>
          </Dialog>
          <form
            // style={{ padding: 24 }}
            onSubmit={handleSubmit(handleOnSubmitForm)}
          >
            {closeDialog && (
              <Fragment>
                <AppBar color="default" position="sticky">
                  <Toolbar
                    className={classes.saveToolbar}
                    sx={{ padding: 1, flexDirection: { sm: "row !important" } }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ flexGrow: 1, width: "100%" }}
                    >
                      <Grid container alignItems={"center"}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={closeDialog}
                          aria-label="close"
                          size="large"
                        >
                          <Close />
                        </IconButton>
                        <Typography color="inherit" variant="h6">
                          {t("createShipment")}
                        </Typography>
                      </Grid>
                      {screenIsSmall && savButton}
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ flexGrow: 1, width: "100%" }}
                    >
                      {ShowReceiveInWareHouse && (
                        <MuiSwitch
                          edge="end"
                          name="shipment.receiveInWarehouse"
                          label={t("receiveInWarehouse")}
                          control={control}
                          onChange={localStorage.setItem(
                            "receiveInWarehouse",
                            watch("shipment.receiveInWarehouse")
                          )}
                        />
                      )}
                      {anotherShipmentSwitch}
                      {!screenIsSmall && savButton}
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Fragment>
            )}
            <Box sx={{ p: 3 }} className={classes.paperBackground}>
              <Grid
                // component="form"
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid
                  container
                  xs={12}
                  className={classes.noPadding}
                  justifyContent={
                    "space-between"
                    // isWidthUp("md", props.width) ? "space-between" : "center"
                  }
                >
                  {!closeDialog && (
                    <Grid
                      justifyContent="space-between"
                      xs={12}
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <Typography variant="h6" color={'text.primary'}>{t("theShipment")}</Typography>
                      <Box>
                        {ShowReceiveInWareHouse && (
                          <MuiSwitch
                            edge="end"
                            name="shipment.receiveInWarehouse"
                            label={t("receiveInWarehouse")}
                            control={control}
                            onChange={localStorage.setItem(
                              "receiveInWarehouse",
                              watch("shipment.receiveInWarehouse")
                            )}
                          />
                        )}
                      </Box>
                    </Grid>
                  )}
                  <Grid
                    xs={12}
                    md={6}
                    p={3}
                    container
                  // className={classes.sectionMargin}
                  // component={Grid}
                  >
                    <Paper
                      xs={12}
                      // spacing={2}
                      container
                      // className={classes.sectionMargin}
                      component={Grid}
                    >
                      <Grid xs={12} alignItems="flex-start">
                        <Typography variant="h6" gutterBottom color={"text.primary"}>
                          {t("shipmentDetails")}
                        </Typography>
                      </Grid>
                      <Can showException permission={datePermission}>
                        <Grid xs={12} alignItems="flex-start">
                          <MUIDateTime
                            name="shipment.date"
                            label={t("theDate")}
                            control={control}
                            value={createdAtState}
                            onChange={(e) => setCreatedAtState(e)}
                          />
                        </Grid>
                      </Can>
                      <Can permission={deliveryDatePermission} showException>
                        <Grid xs={12} alignItems="flex-start">
                          <MUIDate
                            name="shipment.deliveryDate"
                            label={t("redeliveryDate")}
                            control={control}
                            value={deliveryDate}
                            onChange={(date) => setDeliveryDate(date)}
                            // disablePast
                            shouldDisableDate={(day) => {
                              if (new Date(editDeliveryDate).setHours(0, 0, 0, 0) === new Date(day).setHours(0, 0, 0, 0)) {
                                return false
                              } else {
                                return new Date(day).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                              }
                            }}
                            onError={(resone, value) => {
                              setError("deliveryDate", { message: resone });
                            }}
                            InputProps={{
                              ...{
                                endAdornment: (
                                  <IconButton
                                    size="small"
                                    key={"deliveryBefore"}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      resetStartDate();
                                      setValue("deliveryBefore", "");
                                    }}
                                  >
                                    <Clear size="small" />
                                  </IconButton>
                                ),
                              },
                            }}
                          />

                        </Grid>
                      </Can>
                      <Can showException permission={codePermission}>
                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            disabled={unEditable}
                            name={"shipment.code"}
                            label={t("shipmentCode")}
                            errors={errors}
                            rules={
                              urlUpdateShipmentId && {
                                required: t("fieldIsRequired"),
                              }
                            }
                            onKeyPress={(event) => {
                              if (
                                event.key === "Enter" &&
                                user.hasPermission("shipping.barcode.list")
                              ) {
                                getCustomer();
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    display: user.hasPermission(
                                      "shipping.barcode.list"
                                    )
                                      ? "flex"
                                      : "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  <SearchIcon onClick={getCustomer} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Can>
                      <Can
                        showException
                        permission="shipping.shipment.choose_branch"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <ListBranches
                            defaultValue={autocompleteValues.originBranch}
                            control={control}
                            errors={errors}
                            disabled={
                              unEditable ||
                              Boolean(
                                urlUpdateShipmentId &&
                                !updateBranchConditionAndPaymentType
                              )
                            }
                            rules={{ required: t("fieldIsRequired") }}
                            name={"shipment.originBranchId"}
                            label={t("originBranch")}
                            skip={
                              urlUpdateShipmentId && !updateShipmentDetails?.id
                            }
                            skipDefaultBranch={Boolean(urlUpdateShipmentId)}
                            onChangeValue={(e) => {
                              const fieldsName = ["transactionTypeId"];
                              const haveTransaction = transactionData?.filter((i) => i.id === watch('shipment.transactionTypeId'))
                              !haveTransaction && fieldsName.forEach((i) => {
                                setValue(`shipment.${i}`, "");
                              });
                              setCalculationChecker({});
                            }}
                          />
                        </Grid>
                      </Can>
                      <Can
                        showException
                        permission={
                          user.hasPermission(
                            "shipping.shipment.choose_branch"
                          ) &&
                          (watch("shipment.typeCode") === "RTS" ||
                            Boolean(
                              allowWarehousing &&
                              watch("shipment.typeCode") === "FDP"
                            ))
                        }
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <ListBranches
                            control={control}
                            errors={errors}
                            parseData={(data) => parseData(data)}
                            query={LIST_BRANCHES_DROPDOWN.query}
                            disabled={
                              unEditable ||
                              Boolean(
                                urlUpdateShipmentId &&
                                !updateBranchConditionAndPaymentType
                              )
                            }
                            rules={{ required: t("fieldIsRequired") }}
                            name={"shipment.branchId"}
                            label={t("currentBranch")}
                            skip={
                              urlUpdateShipmentId && !updateShipmentDetails?.id
                            }
                            defaultValue={autocompleteValues.branch}
                          />
                        </Grid>
                      </Can>

                      <Can
                        showException
                        permission="shipping.shipment.choose_transaction_type"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <CustomAutocomplete
                            defaultValue={autocompleteValues.transactionType}
                            control={control}
                            errors={errors}
                            disabled={
                              unEditable ||
                              Boolean(!watch("shipment.originBranchId"))
                            }
                            parseData={(data) => transactionDataLoaded(data)}
                            name={"shipment.transactionTypeId"}
                            label={t("transactionType")}
                            rules={{ required: t("fieldIsRequired") }}
                            query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                            skip={
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer ||
                              Boolean(!watch("shipment.originBranchId"))
                            }
                            variables={{
                              input: {
                                type: "PKR",
                                branchId: {
                                  value: watch("shipment.originBranchId"),
                                  includeNull: true,
                                },
                              },
                            }}
                            onCompleted={(data) => setTransactionData(data?.listTransactionTypesDropdown)}
                          />
                        </Grid>
                      </Can>

                      <Grid xs={12} alignItems="flex-start">
                        <CustomAutocomplete
                          control={control}
                          errors={errors}
                          disabled={
                            unEditable ||
                            Boolean(!watch("shipment.serviceId"))
                            // (warehousingSystem && !productsSelectedLength)
                          }
                          rules={{ required: t("fieldIsRequired") }}
                          name={"shipment.typeCode"}
                          label={t("packageType")}
                          skip={
                            (urlUpdateShipmentId &&
                              !updateShipmentDetails?.id) ||
                            invalidCustomer ||
                            !watch("shipment.serviceId")
                          }
                          onCompleted={onCompleteShipmentType}
                          defaultValue={autocompleteValues.typeCode}
                          variables={{ serviceId: watch("shipment.serviceId") }}
                          parseData={(data) => parseData(data)}
                          query={LIST_SHIPMENT_TYPES_DROPDOWN.query}
                          valueKey="code"
                          onChangeValue={(e) => {
                            clearErrors("shipment.piecesCount");
                            clearErrors("shipment.returnPiecesCount");
                            if (!["PTP", "RTS"].includes(e.code)) {
                              EmptyTextFieldsIN()
                            }
                            if (e.code !== "PTP" && e.code !== "FDP") {
                              EmptyFieldsChangeType()
                            }
                            if (e.code === "PDP" || e.code === "PTP") {
                              setValue("shipment.returnPiecesCount", 1);

                              e.code === "PDP"
                                ? setValue("shipment.piecesCount", 2)
                                : setValue("shipment.piecesCount", 1);
                            } else {
                              setValue("shipment.piecesCount", 1);
                              clearErrors("shipment.piecesCount");
                              unregister("shipment.returnPiecesCount");
                            }
                            setChange((prev) => !prev);
                          }}
                        />
                      </Grid>

                      <Grid xs={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          name={"shipment.packageDescription"}
                          label={t("packageDescription")}
                          rows={
                            allowWarehousing && (!productsSelectedLength || (showProductsTableRTS && !productsSelectedLengthIN)) ? 4 : 1
                          }
                        />
                      </Grid>

                      <Grid xs={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          readOnly={allowWarehousing && !productsSelectedLength}
                          type={"number"}
                          name={"shipment.weight"}
                          label={t("packageWeight")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("weightValidation"),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(weightTimer);
                            weightTimer = setTimeout(() => {
                              calcFeesHandler({
                                value: parseFloat(value),
                                name: "weight",
                              });
                            }, 1000);
                          }}
                        />
                      </Grid>

                      <Grid xs={4} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          type={"number"}
                          name={"shipment.length"}
                          label={t("length")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("validation:min", {
                                field: t("length"),
                                number: "0",
                              }),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(lengthTimer);
                            lengthTimer = setTimeout(() => {
                              if (value && value >= 0) {
                                calcFeesHandler({
                                  value: parseFloat(value),
                                  name: "length",
                                });
                              }
                            }, 1000);
                          }}
                        />
                      </Grid>
                      <Grid xs={4} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          type={"number"}
                          name={"shipment.width"}
                          label={t("width")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("validation:min", {
                                field: t("width"),
                                number: "0",
                              }),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(widthTimer);
                            widthTimer = setTimeout(() => {
                              if (value && value >= 0) {
                                calcFeesHandler({
                                  value: parseFloat(value),
                                  name: "width",
                                });
                              }
                            }, 1000);
                          }}
                        />
                      </Grid>
                      <Grid xs={4} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          type={"number"}
                          name={"shipment.height"}
                          label={t("height")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("validation:min", {
                                field: t("height"),
                                number: "0",
                              }),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(heightTimer);
                            heightTimer = setTimeout(() => {
                              if (value && value >= 0) {
                                calcFeesHandler({
                                  value: parseFloat(value),
                                  name: "height",
                                });
                              }
                            }, 1000);
                          }}
                        />
                      </Grid>
                      {/* test */}
                      <Grid
                        xs={shipmentTypeValidation ? 6 : 12}
                        alignItems="flex-start"
                      >
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          readOnly={allowWarehousing && !productsSelectedLength}
                          type={"number"}
                          name={"shipment.piecesCount"}
                          label={t("pieceCount")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              ...(watch("shipment.typeCode") === "PDP"
                                ? {
                                  value: 2,
                                  message: t("validation:min", {
                                    field: t("pieceCount"),
                                    number: "2",
                                  }),
                                }
                                : {
                                  value: 1,
                                  message: t("validation:min", {
                                    field: t("pieceCount"),
                                    number: "1",
                                  }),
                                }),
                            },
                          }}
                        />
                      </Grid>

                      {shipmentTypeValidation && (
                        <Grid xs={6} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            readOnly={allowWarehousing && !productsSelectedLengthIN}
                            disabled={unEditable}
                            type="number"
                            name={"shipment.returnPiecesCount"}
                            label={t("returnPiecesCount")}
                            rules={{
                              required: t("fieldIsRequired"),
                              min: {
                                value: 1,
                                message: t("validation:min", {
                                  field: t("pieceCount"),
                                  number: "1",
                                }),
                              },
                              validate: {
                                ...(watch("shipment.typeCode") === "PDP" && {
                                  max: (value) =>
                                    +value >= +watch("shipment.piecesCount")
                                      ? t("validation:lt", {
                                        field1: t("returnPiecesCount"),
                                        field2: t("pieceCount"),
                                      })
                                      : true,
                                }),
                              },
                            }}
                          />
                        </Grid>
                      )}

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <CustomAutocomplete
                          control={control}
                          errors={errors}
                          disabled={
                            !Boolean(customerId) ||
                            unEditable ||
                            (urlUpdateShipmentId &&
                              !updateBranchConditionAndPaymentType)
                          }
                          rules={{ required: t("fieldIsRequired") }}
                          name={"shipment.paymentType"}
                          label={t("paymentType")}
                          skip={!Boolean(customerId) || disPaymentType}
                          onCompleted={onCompletePaymentType}
                          defaultValue={autocompleteValues.paymentType}
                          variables={{
                            ...(watch("shipment.customerId") && {
                              input: {
                                customerId: watch("shipment.customerId"),
                              },
                            }),
                          }}
                          parseData={(data) => parseData(data)}
                          query={LIST_PAYMENT_TYPES_DROPDOWN.query}
                          valueKey="code"
                          onChangeValue={(e) => {
                            calcFeesHandler({
                              value: e.code,
                              name: "paymentType",
                            });
                            // if (e.code === "CASH") {
                            //   setValue("shipment.weight", 1);
                            //   calcFeesHandler({ value: 1, name: "weight" });
                            // }
                            if (e.code === "CASH" || e.code === "CRDT") {
                              setValue("shipment.price", 0);
                              setValue("shipment.priceType", "INCLD");
                              calcFeesHandler({ value: 0, name: "price" });
                              calcFeesHandler({
                                value: "INCLD",
                                name: "priceType",
                              });
                            }
                            if (
                              e.code !== "COLC" &&
                              e.code !== "VISA" &&
                              e.code !== "CRDT"
                            ) {
                              allowWarehousing &&
                                !productsSelectedLength &&
                                EmptyTextFields();
                            }
                            if (e.code !== "CRDT") {
                              allowWarehousing &&
                                !productsSelectedLength &&
                                setChange((prev) => !prev);
                            }
                          }}
                        />
                      </Grid>
                      <Can showException permission={showPrice}>
                        <Grid xs={12} sm={12} alignItems="flex-start">
                          <ControlMUItextMoney
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            updatedvalue={getValues("shipment.price")}
                            readOnly={Boolean(
                              watch("shipment.paymentType") === "CASH" ||
                              watch("shipment.paymentType") === "CRDT" ||
                              (allowWarehousing && !productsSelectedLength)
                            )}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.price"}
                            label={t("packagePrice")}
                            onChange={(e) => {
                              const value = e.target.value;
                              clearTimeout(priceTimer);
                              priceTimer = setTimeout(() => {
                                calcFeesHandler({
                                  value: value === "-" ? 0 : value,
                                  name: "price",
                                });
                              }, 1000);
                            }}
                          />
                          {/*
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            readOnly={Boolean(
                              watch("shipment.paymentType") === "CASH" ||
                              watch("shipment.paymentType") === "CRDT" ||
                              (allowWarehousing && !productsSelectedLength)
                            )}
                            type={"number"}
                            name={"shipment.price"}
                            label={t("packagePrice")}
                            InputProps={{
                              ...(currencyCode && {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {currencyCode}
                                  </InputAdornment>
                                ),
                              }),
                            }}
                            rules={{
                              required: t("fieldIsRequired"),
                              //   max: {
                              //     ...(watch("shipment.type") === "RTS"
                              //       && {
                              //       value: 0,
                              //       message: t("validation:max", {
                              //         field: t("priceType"),
                              //         number: "0",
                              //       }),
                              //     }),
                              //   },
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              clearTimeout(priceTimer);
                              priceTimer = setTimeout(() => {
                                calcFeesHandler({
                                  value: value,
                                  name: "price",
                                });
                              }, 1000);
                            }}
                          /> */}
                        </Grid>
                      </Can>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <LookupDropdown
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          rules={{
                            required: t("fieldIsRequired"),
                          }}
                          name={"shipment.priceType"}
                          label={t("priceType")}
                          readOnly={Boolean(
                            watch("shipment.paymentType") === "CASH" ||
                            watch("shipment.paymentType") === "CRDT"
                          )}
                          onChanges={(e) => {
                            calcFeesHandler({
                              value: e.target.value,
                              name: "priceType",
                            });
                          }}
                          skip={
                            (urlUpdateShipmentId &&
                              !updateShipmentDetails?.id) ||
                            invalidCustomer
                          }
                          variables={{ input: { code: "SHP_PRICE_TYPE" } }}
                          onCompleted={onCompletePriceType}
                        />
                      </Grid>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          name={"shipment.refNumber"}
                          label={t("refNumber")}
                        />
                      </Grid>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <LookupDropdown
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          rules={{ required: t("fieldIsRequired") }}
                          name={"shipment.openable"}
                          label={t("packageOpenPossibility")}
                          skip={
                            (urlUpdateShipmentId &&
                              !updateShipmentDetails?.id) ||
                            invalidCustomer
                          }
                          variables={{ input: { code: "SHP_OPEN_PACKAGE" } }}
                          onCompleted={onOpenable}
                        />
                      </Grid>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          name={"shipment.notes"}
                          label={t("notes")}
                          rows={3}
                        />
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    md={6}
                    // spacing={2}
                    alignContent="flex-start"
                    justifyContent="center"
                    className={clsx(classes.gridWidth)}
                  >
                    <Grid
                      container
                      xs={12}
                      p={3}
                      // component={Grid}
                      alignContent="flex-start"
                    >
                      <Paper
                        container
                        xs={12}
                        // spacing={2}
                        component={Grid}
                        alignContent="flex-start"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <Typography variant="h6" gutterBottom color={"text.primary"}>
                            {t("senderDetails")}
                          </Typography>
                        </Grid>

                        <Can
                          showException
                          permission="shipping.shipment.choose_customer"
                        >
                          <Grid xs={12} sm={12} alignItems="flex-start">
                            <CustomAutocomplete
                              defaultValue={autocompleteValues.customer}
                              control={control}
                              errors={errors}
                              rules={{ required: t("fieldIsRequired") }}
                              name={"shipment.customerId"}
                              label={t("customer")}
                              disabled={
                                unEditable || !watch("shipment.originBranchId")
                              }
                              parseData={(data) => parseData(data)}
                              query={LIST_CUSTOMERS_DROPDOWN.query}
                              skip={
                                (urlUpdateShipmentId &&
                                  !updateShipmentDetails?.id) ||
                                invalidCustomer ||
                                !watch("shipment.originBranchId")
                              }
                              variables={{
                                input: {
                                  branchId: {
                                    value: watch("shipment.originBranchId"),
                                    includeNull: true,
                                  },
                                  active: true,
                                  typeCode: ["MERCHANT", "INDIVIDUAL"],
                                },
                              }}
                              onCompleted={(data) => {
                                currentValueMatchingNewValues(
                                  data,
                                  "customerId"
                                );
                              }}
                              onChangeValue={(e) => {
                                customerChange(e);
                                customerProductData(e)
                                setChangeCustomer(watch("shipment.customerId"))
                              }}
                            />
                          </Grid>
                        </Can>

                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.senderName"}
                            label={t("senderName")}
                            rules={{ required: t("fieldIsRequired") }}
                          />
                        </Grid>
                        <Grid xs={12} alignItems="flex-start">
                          <CustomAutocomplete
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              (customerPermission
                                ? !watch("shipment.customerId")
                                : false) || unEditable
                            }
                            name={"shipment.serviceId"}
                            label={t("service")}
                            onCompleted={onCompleteService}
                            onChangeValue={(e) => {
                              const value = e?.id;

                              // const fieldsName = [
                              //   "senderZoneId",
                              //   "senderSubzoneId",
                              //   "recipientZoneId",
                              //   "recipientSubzoneId",
                              // ];

                              // setRecipientZones(null);
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: true,
                              }));
                              calcFeesHandler({
                                value: value,
                                name: "serviceId",
                              });

                              // fieldsName.forEach((i) => {
                              //   setValue(`shipment.${i}`, "");
                              // });
                              // setValue("shipment.type", "");
                            }}
                            query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              (customerPermission
                                ? !calculationChecker?.["customerId"] ||
                                !watch("shipment.customerId")
                                : false) ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer ||
                              settingsLoading ||
                              !customerType
                            }
                            variables={{
                              input: {
                                ...(!user.account && {
                                  customerId: watch("shipment.customerId"),
                                }),
                                active: true,
                                customerTypeCode: customerType,
                              },
                            }}
                            defaultValue={autocompleteValues.service}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(!watch("shipment.serviceId"))
                            }
                            label={t("zone")}
                            name={"shipment.senderZoneId"}
                            onCompleted={(data) =>
                              onCompleteZones(data, "senderZone")
                            }
                            onChangeValue={(e) => {
                              const value = e?.id;
                              setRecipientZones(null);
                              // const fieldsName = [
                              //   // "senderSubzoneId",
                              //   "recipientZoneId",
                              //   "recipientSubzoneId",
                              // ];
                              // fieldsName.forEach((i) => {
                              //   setValue(`shipment.${i}`, "");
                              // });
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: prev.serviceId,
                                senderZoneId: true,
                              }));

                              calcFeesHandler({
                                value: value,
                                name: "senderZoneId",
                              });
                            }}
                            query={LIST_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !calculationChecker?.["serviceId"] ||
                              !customerId ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer
                            }
                            variables={{
                              input: {
                                active: true,
                                parentId: null,
                                ...(multiCountry && countryId && { countryId }),
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  ...(!user.account && {
                                    customerId: customerId,
                                  }),
                                },
                              },
                            }}
                            defaultValue={autocompleteValues.senderZone}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(!watch("shipment.senderZoneId"))
                            }
                            name={"shipment.senderSubzoneId"}
                            label={t("subzone")}
                            onCompleted={onCompleteSubzones}
                            onChangeValue={onChangeSenderSubzone}
                            query={LIST_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !calculationChecker?.["senderZoneId"] ||
                              !watch("shipment.senderZoneId") ||
                              !customerId ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer
                            }
                            variables={{
                              input: {
                                active: true,
                                parentId: watch("shipment.senderZoneId"),
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  ...(!user.account && {
                                    customerId: customerId,
                                  }),
                                },
                              },
                            }}
                            defaultValue={autocompleteValues.senderSubzone}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <MuiPhone
                            setValue={setValue}
                            codeValue={getValues().senderPhoneCode}
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.senderPhone"}
                            selectName={"senderPhoneCode"}
                            label={t("phone")}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            onBlur={(e) => {
                              if (Boolean(!getValues().shipment.senderMobile)) {
                                setValue(
                                  "shipment.senderMobile",
                                  getValues().shipment.senderPhone,
                                  { shouldDirty: true }
                                );
                                setValue(
                                  "senderMobileCode",
                                  getValues().senderPhoneCode,
                                  { shouldDirty: true }
                                );
                              }
                            }}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">

                          <MuiPhone
                            setValue={setValue}
                            codeValue={watch("senderMobileCode")}
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.senderMobile"}
                            selectName={"senderMobileCode"}
                            label={t("mobile")}
                            rules={{
                              required: t("fieldIsRequired"),
                            }} />
                        </Grid>
                        <Can permission={forcePostalCode} showException>
                          <Grid xs={12} alignItems="flex-start">
                            <ControlMUItextField
                              control={control}
                              errors={errors}
                              disabled={unEditable}
                              rules={{
                                required: t("fieldIsRequired"),
                              }}
                              name={"shipment.senderPostalCode"}
                              label={t("postalCode")}
                            />
                          </Grid>
                        </Can>
                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.senderAddress"}
                            label={t("address")}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  disabled={unEditable}
                                  onClick={() => {
                                    handleClickOpenMapSender();
                                  }}
                                  size="large"
                                >
                                  <LocationOn />
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      p={3}
                      // component={Grid}

                      alignContent="flex-start"
                    >
                      <Paper
                        container
                        xs={12}
                        // spacing={2}
                        component={Grid}
                        alignContent="flex-start"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <Typography variant="h6" gutterBottom color={"text.primary"}>
                            {t("recipientDetails")}
                          </Typography>
                        </Grid>

                        <Can permission={multiCountry} showException>
                          <Grid xs={12} alignItems="flex-start">
                            <CustomAutocomplete
                              control={control}
                              errors={errors}
                              rules={{ required: t("fieldIsRequired") }}
                              name={"shipment.countryId"}
                              label={t("country")}
                              disabled={
                                unEditable ||
                                Boolean(!watch("shipment.senderSubzoneId"))
                              }
                              onChangeValue={(e) => {
                                setRecipientZones(null);
                                // const fieldsName = ["recipientZoneId", "recipientSubzoneId"];
                                // fieldsName.forEach((i) => {
                                //   setValue(`shipment.${i}`, "");
                                // });
                                setCalculationChecker((prev) => ({
                                  customerId: prev.customerId,
                                  serviceId: prev.serviceId,
                                  senderZoneId: prev.senderZoneId,
                                  senderSubzoneId: prev.senderSubzoneId,
                                }));
                              }}
                              query={LIST_COUNTRIES_DROPDOWN.query}
                              parseData={(data) => parseData(data)}
                              defaultValue={autocompleteValues.country}
                            />
                          </Grid>
                        </Can>

                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.recipientName"}
                            label={t("recipient")}
                            aria-describedby={recipientPopoverId}
                            // aria-owns={
                            //   openPopover ? "recipient-popover-id" : undefined
                            // }
                            aria-haspopup="true"
                            InputProps={{
                              ...(listConsigneePermission && {
                                endAdornment: (
                                  <Fragment>
                                    <RecipientListPopover
                                      disabled={
                                        unEditable ||
                                        //this to make sure that is all fields ready to open popover
                                        !Boolean(
                                          recipientZonesList.zonesList
                                        ) ||
                                        (multiCountry &&
                                          watch("shipment.countryId") === "")
                                      }
                                      changeCustomer={changeCustomer}
                                      id={recipientPopoverId}
                                      anchorEl={anchorElPopover}
                                      handleClose={handleCloseRecipentPopover}
                                      onCompleted={onComleteRecipientDetails}
                                      handleOpenPopover={handleOpenPopover}
                                      consigneeVariables={{
                                        customerId: customerId,
                                        ...(multiCountry && {
                                          countryId:
                                            watch("shipment.countryId"),
                                        }),
                                      }}
                                    />
                                  </Fragment>
                                ),
                              }),
                            }}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            defaultValue={autocompleteValues.recipientZone}
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(watch("shipment.serviceId") === "") ||
                              watch("shipment.senderZoneId") === "" ||
                              watch("shipment.senderSubzoneId") === "" ||
                              (multiCountry &&
                                watch("shipment.countryId") === "")
                            }
                            name={"shipment.recipientZoneId"}
                            label={t("zone")}
                            onChangeValue={(e) => {
                              const value = e?.id;
                              setRecipientZones(null);
                              // setValue("shipment.recipientSubzoneId", "");
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: prev.serviceId,
                                senderZoneId: prev.senderZoneId,
                                senderSubzoneId: prev.senderSubzoneId,
                                recipientZoneId: true,
                              }));
                              calcFeesHandler({
                                value: value,
                                name: "recipientZoneId",
                              });

                              // setZoneName((prevState) => {
                              //   return {
                              //     ...prevState,
                              //     zone: e?.name,
                              //     subzone: "",
                              //   };
                              // });
                            }}
                            query={LIST_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !customerId ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer ||
                              !watch("shipment.senderZoneId") ||
                              !calculationChecker?.["senderSubzoneId"] ||
                              !watch("shipment.senderSubzoneId") ||
                              (multiCountry &&
                                !watch("shipment.countryId"))
                            }
                            variables={{
                              input: {
                                active: true,
                                countryId: watch("shipment.countryId"),
                                parentId: null,
                                // branchAware: false,
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  ...(!user.account && {
                                    customerId: customerId,
                                  }),
                                  fromZoneId: watch("shipment.senderZoneId"),
                                  fromSubzoneId: watch(
                                    "shipment.senderSubzoneId"
                                  ),
                                },
                              },
                            }}
                            onCompleted={onCompleteRecipientZones}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            defaultValue={autocompleteValues.recipientSubzone}
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(
                                !watch("shipment.recipientZoneId")
                              )
                            }
                            name={"shipment.recipientSubzoneId"}
                            label={t("subzone")}
                            onChangeValue={(e) => {
                              const value = e?.id;
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: prev.serviceId,
                                senderZoneId: prev.senderZoneId,
                                senderSubzoneId: prev.senderSubzoneId,
                                recipientZoneId: prev.recipientZoneId,
                                recipientSubzoneId: true,
                              }));
                              calcFeesHandler({
                                value: value,
                                name: "recipientSubzoneId",
                              });

                              // setZoneName((prevState) => ({
                              //   ...prevState,
                              //   subzone: e?.name,
                              // }));
                            }}
                            query={LIST_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !customerId ||
                              !watch("shipment.recipientZoneId") ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer ||
                              watch("shipment.senderZoneId") === "" ||
                              !calculationChecker?.["recipientZoneId"] ||
                              watch("shipment.senderSubzoneId") === ""
                            }
                            variables={{
                              input: {
                                active: true,
                                // branchAware: false,
                                parentId: watch("shipment.recipientZoneId"),
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  ...(!user.account && {
                                    customerId: customerId,
                                  }),
                                  fromZoneId: watch("shipment.senderZoneId"),
                                  fromSubzoneId: watch(
                                    "shipment.senderSubzoneId"
                                  ),
                                },
                              },
                            }}
                            onCompleted={onCompleteRecipientSubzones}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">
                          <MuiPhone
                            setValue={setValue}
                            codeValue={getValues().recipientPhoneCode}
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.recipientPhone"}
                            selectName={"recipientPhoneCode"}
                            label={t("phone")}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            onBlur={(e) => {
                              if (Boolean(!getValues().shipment.recipientMobile)) {
                                setValue(
                                  "shipment.recipientMobile",
                                  getValues().shipment.recipientPhone,
                                  { shouldDirty: true }
                                );
                                setValue(
                                  "recipientMobileCode",
                                  getValues().recipientPhoneCode,
                                  { shouldDirty: true }
                                );
                              }
                            }}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">
                          <MuiPhone
                            setValue={setValue}
                            codeValue={watch("recipientMobileCode")}
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.recipientMobile"}
                            selectName={"recipientMobileCode"}
                            label={t("mobile")}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                          />
                        </Grid>
                        <Can permission={forcePostalCode} showException>
                          <Grid xs={12} alignItems="flex-start">
                            <ControlMUItextField
                              control={control}
                              errors={errors}
                              disabled={unEditable}
                              rules={{
                                required: t("fieldIsRequired"),
                              }}
                              name={"shipment.recipientPostalCode"}
                              label={t("postalCode")}
                            />
                          </Grid>
                        </Can>
                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.recipientAddress"}
                            label={t("address")}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  disabled={unEditable}
                                  onClick={() => {
                                    handleClickOpenMapRecipient();
                                  }}
                                  size="large"
                                >
                                  <LocationOn />
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {(showProductsTableFDP || showProductsTablePTP || showProductsTableRTS) &&
                    <Can showException permission={adminWarehousingPermission}>
                      <Grid
                        container
                        xs={12}
                        p={2}
                        // component={Grid}
                        alignContent="flex-start"
                      >
                        <FormTabs
                          classes={classes}
                          productsSelected={productsSelected}
                          setChange={setChange}
                          EmptyTextFields={EmptyTextFields}
                          setPickedProduct={setPickedProduct}

                          productsSelectedIN={productsSelectedIN}
                          setPickedProductIN={setPickedProductIN}
                          EmptyTextFieldsIN={EmptyTextFieldsIN}
                          setChangeIN={setChangeIN}

                          showProductInOut={showProductsTablePTP}
                          showProductIn={showProductsTableRTS}
                          showProductOut={showProductsTableFDP}

                          CRDTShipment={CRDTShipment}
                          setChangeDes={setChangeDes}
                          unEditable={unEditable}
                          errors={errors}
                          control={control}
                          clearErrors={clearErrors}
                          productDrawerHandler={() => productDrawerHandler(anchor, true)}
                          productDrawerHandlerIN={() => productDrawerHandlerIN(anchor, true)}
                        />
                      </Grid>
                    </Can>
                  }

                  {!closeDialog && (
                    <Grid xs={12} display="flex" justifyContent="flex-end">
                      {savButton}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
          {/* <div className={classes.pushContent} /> */}
          <Grid
            container
            className={classes.footer}
            justifyContent="flex-start"
          >
            {claculateFeesLoading && (
              <Box className={classes.loadingFooter}>
                <CustomSpinner name="PulseLoader" size={10} margin={3} />
              </Box>
            )}
            <Grid container component="span" xs={8} sm={10}>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("amount")}
                  currencyCode={currencyCode}
                  value={calcFeesValue.amount}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("deliveryFees")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.deliveryFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("extraWeightCost")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.extraWeightFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("collectionFees")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.collectionFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("returnFees")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.returnFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              {!user.account && <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("deliveryCommission")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  noCalc
                  name={"shipment.deliveryCommission"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>}
              {watch("shipment.paymentType") === "CRDT" && (
                <Grid component="span" xs={6} sm>
                  <CalculationResult
                    title={t("tax")}
                    currencyCode={currencyCode}
                    value={calcFeesValue.tax}
                  />
                </Grid>
              )}

              {watch("shipment.paymentType") === "CRDT" && (
                <Grid component="span" xs={6} sm>
                  <CalculationResult
                    title={t("postFees")}
                    currencyCode={currencyCode}
                    value={calcFeesValue.post}
                  />
                </Grid>
              )}
            </Grid>

            <Grid component="span" xs={4} sm={2}>
              <CalculationResult
                title={t("totalCost")}
                currencyCode={currencyCode}
                valuesx={classes.totalCalc}
                value={calcFeesValue.total}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </Root>
  );
};

export default withRouter(SaveShipment);
let timeout = {};

const CalculationResult = (props) => {
  const {
    title,
    valueStyle,
    value,
    control,
    editable,
    name,
    calcFeesHandler,
    readOnly,
    currencyCode,
    noCalc,
  } = props;

  useEffect(() => {
    return () => {
      clearTimeout(timeout[name]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const feesField = control && (
    <ControlMUItextField
      control={control}
      name={name}
      InputProps={{
        ...(currencyCode && {
          endAdornment: (
            <InputAdornment position="end">{currencyCode}</InputAdornment>
          ),
        }),
      }}
      onChange={(e) => {
        if (noCalc) {
          return
        }
        const fieldValue = e.target.value;
        const objName = name.split(".")[1];
        clearTimeout(timeout[name]);

        timeout[name] = setTimeout(() => {
          calcFeesHandler({
            value: parseFloat(fieldValue),
            name: objName,
            feesAvailable: true,
          });
        }, 1000);
      }}
      disabled={editable}
      className={classes.feesField}
      variant="standard"
      readOnly={readOnly}
    />
  );
  return (
    <Fragment>
      <p className={classes.calcTitle}>{title}</p>
      {!control && (
        <p className={clsx(classes.calcValue, valueStyle)}>
          {formatMoney(value?.toFixed(2)) ?? "--"}
          {" " + currencyCode}
        </p>
      )}
      {feesField}
    </Fragment>
  );
};
